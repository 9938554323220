<template>
  <v-layout align-start justify-start column fill-height wrap class="white">
    <router-view />
  </v-layout>
</template>

<script>
export default {
  name: 'pibot-survey-planning-view'
}
</script>
